.App {
	/* height: 100vh;   */
	width: 100%;
	background-color: #f7f7f8;
}

.body {
	height: 93vh;
	display: flex;
	margin: 1rem;
}

.page {
	min-height: 100vh;
	/* width: 100vw; */
	/* background-color: #f5f5f5; */
	border: 1px solid black;
	display: flex;
}

.outline {
	border: 1px solid black;
}

.jcse {
	justify-content: space-evenly;
}

.aic {
	align-items: center;
}

.w100 {
	width: 100%;
}

.h100 {
	height: 100%;
}

.row {
	display: flex;
	flex-direction: row;
}

.column {
	display: flex;
	flex-direction: column;
}

.gradientText {
	background: linear-gradient(0deg, #5fc52e 0%, #6eee87 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	display: inline-block; /* Needed to apply gradient on inline elements like span */
}
